import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import PropTypes from "prop-types";

const GoogleButton = ({ link, onClick }) => {
  
  return (
    <a
      className="btn mx-1 p-2 btn-lg"
      style={{border: "none", outline: "0"}}
      onClick={() => onClick()}
    >
      <i className="bi bi-google" style={{ color: "#dd4b39" }}></i>
    </a>
  );
};

GoogleButton.propTypes = {
  link: PropTypes.string,
  onClick: PropTypes.func,
};

export default GoogleButton;
