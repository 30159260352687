import React, { ReactNode, useEffect, useState } from "react";
import { useOAuth2 } from "@contexts/OAuth2Context";
import ErrorPage from "@pages/Error";
import LoadingPage from "@authien/components/LoadingPage";
import { OpenIdService } from "@authien/services/auth";

import { jwtVerify, importJWK, JWK } from "jose";
import { useLocation } from "react-router-dom";

type RenderOnBoardingProps = { children: ReactNode };

export const RenderOnBoarding = ({ children }: RenderOnBoardingProps) => {
  const { state } = useOAuth2();
  const [verifiedCode, setVerifiedCode] = useState<boolean>();

  const location = useLocation();

  const currentPage = location.pathname;
  const is_correct_route = currentPage.startsWith("/external/");

  const initializeVerifyCode = async () => {
    const response = await verifyCode(state);
    setVerifiedCode(response);
  };

  useEffect(() => {
    if (is_correct_route && state) initializeVerifyCode();
  }, []);

  if (!is_correct_route) return <></>;

  if (!state) return <ErrorPage missingArguments={["code"]} />;

  if (verifiedCode === undefined) return <LoadingPage />;

  if (verifiedCode === false) return <h3>Invalid Code</h3>;

  return <>{children}</>;
};

async function verifyCode(code: string): Promise<boolean> {
  const jwks_datas = await OpenIdService.getOpenIdjwks(); //TODO create a cache for rsaKeyPublic
  const rsaKeyPublic = JSON.parse(JSON.stringify(jwks_datas)).keys[0]; //TODO use kid Header

  const rsaKeyPublicToBase64Url: JWK = {
    ...rsaKeyPublic,
    n: toBase64Url(rsaKeyPublic.n),
  };

  const key = await importJWK(rsaKeyPublicToBase64Url, "RS256");

  try {
    const { payload } = await jwtVerify(code, key);
    console.log("Token is valid:", payload);
    return true;
  } catch (err) {
    console.error("Token verification failed:", err);
  }
  return false;
}

function toBase64Url(base64: string): string {
  return base64.replace(/=/g, "").replace(/\+/g, "-").replace(/\//g, "_");
}
