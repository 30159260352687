import React from "react";
import "../assets/styles/divider.css";
import PropTypes from 'prop-types';

const Divider = ({ children }) => {
  return (
    <div className="divider">
      <p>{ children }</p>
    </div>
  );
};

Divider.propTypes = {
  children: PropTypes.string,
}

export default Divider;
