/**
 * Copyright © 2017-2023 Knowledge for Decisions (K4D). All rights reserved.
 *
 * This code and associated materials are protected by copyright laws and international
 * treaties. Unauthorized use, reproduction, distribution, or modification of any content
 * from Knowledge for Decisions (K4D) is strictly prohibited. For inquiries regarding the
 * use or licensing of K4D's copyrighted materials, please contact us at contact@k4d.com.br.
 * "Knowledge for Decisions (K4D)" and the slogan "Using data to make precise decisions" are
 * registered trademarks of Knowledge for Decisions (K4D).
 */

import axios, { AxiosInstance } from "axios";
import { clientMocks } from "../../../mocks";
import {
  InvalidCredentialException,
  InvalidUserException,
} from "@authien/helpers/exceptions/Exception";
import { OAuth2Type } from "@contexts/OAuth2Context";

interface OAuth2Config {
  BASE_URL?: string;
  CLIENT_ID?: string;
  CLIENT_SECRET?: string;
  TIMEOUT?: string;
  TOKEN_ENDPOINT_URL?: string;
  CLIENT_ENDPOINT_URL?: string;
}

export interface User {
  username: string;
  telephone: string;
  password: string;
  name: string;
}

export interface Client {
  client_id: string;
  clientSecret: string;
  redirect_uris: Array<string>;
  clientLogoURL: string;
  clientName: string;
  clientToken?: string;
  response_types: Array<string>;
  scopes: Array<string>;
}

class OAuth2Service {

  /**
   * @param username
   * @throws InvalidCredentialException
   * @returns User
   */
  checkIdentityFormat(username: string): string {
    const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;

    if (emailPattern.test(username)) return username;
      
    throw new InvalidUserException(username);
  }

  checkPasswordFormat(password: string): string {
    
    if (password.length > 7) return password;
      
    throw new InvalidCredentialException();
  }

  isClientValid(
    client_id: string,
    redirect_uri: string,
    response_type: string,
    scope: string
  ): boolean {
    for (let client of clientMocks) {

      if (
        client_id === client.client_id &&
        client.response_types.includes(response_type) &&
        client.redirect_uris.includes(redirect_uri) &&
        client.scopes.includes(scope)
      )
        return true;
    }

    return false;
  }

  getClientInformation(oauthParams: OAuth2Type): Client | null {
    const { client_id, redirect_uri, response_type, scope } = oauthParams;
    for (let client of clientMocks) {
      if (
        client_id === client.client_id &&
        client.redirect_uris.includes(redirect_uri) &&
        client.response_types.includes(response_type) &&
        client.scopes.includes(scope)
      )
        return client;
      }
    return null;
  }
}

export default OAuth2Service;
