import React, {
  createContext,
  useContext,
  useState,
  Dispatch,
  SetStateAction,
  useMemo,
  ReactNode,
  useEffect,
} from "react";
import { OAuth2QueryParameters } from "@features/authentication/constants/Oauth2";
import OAuth2Service, {
  Client,
} from "@features/authentication/services/OAuth2Service";
import { useLocation } from "react-router-dom";
import { SuccessTokenStepResponse } from "@services/auth";
import { StartAuthenticationRequest } from "@features/authentication/services/OAuth2Request";

export interface OAuth2Type {
  client_id: string;
  redirect_uri: string;
  response_type: string;
  scope: string;
}

export interface OAuth2ContextType {
  oauth2Data: OAuth2Type;
  setOauth2Data: Dispatch<SetStateAction<OAuth2Type>>;
  client: Client | null;
  state: string;
  startAuthenticationRequestResponse: SuccessTokenStepResponse | undefined;
}

const OAuth2Context = createContext<OAuth2ContextType | undefined>(undefined);

type OAuth2ProviderProps = { children: ReactNode };
export const OAuth2Provider = ({ children }: OAuth2ProviderProps) => {
  const [startAuthenticationRequestResponse, setStartAuthenticationRequestResponse] = useState<SuccessTokenStepResponse>()
  const oauth2Params: OAuth2Type = ExtractOAuth2QueryParameters();
  const [oauth2Data, setOauth2Data] = useState<OAuth2Type>({
    ...oauth2Params,
  });

  const oauth2Service = new OAuth2Service();
  const client = oauth2Service.getClientInformation(oauth2Data);
  const state = ExtractParameter(OAuth2QueryParameters.STATE);

  const initializeContextRequestResponse = async () => {
    const response = await StartAuthenticationRequestResponse(oauth2Data);
    setStartAuthenticationRequestResponse(response);
    //FIXME Chamada ineficiente para confirmação dos dados do cliente, esta rota serve para iniciar a autenticação, 
    //porém está sendo utilizada por todos os fluxos para confirmar os dados do cliente antes da execução das páginas
  };

  useEffect(() => {
    initializeContextRequestResponse();
  }, []);

  const contextValues = { oauth2Data, setOauth2Data, client, startAuthenticationRequestResponse, state };
  const initialValues = useMemo(
    () => ({ ...contextValues }),
    [...Object.values(contextValues)]
  );

  return (
    <OAuth2Context.Provider value={initialValues}>
      {children}
    </OAuth2Context.Provider>
  );
};

export const useOAuth2 = () => {
  const context = useContext(OAuth2Context);

  if (!context)
    throw new Error("useOAuth2 must be used within an OAuth2Provider");

  return context;
};

export function ExtractOAuth2QueryParameters(): OAuth2Type {
  const location = useLocation();

  const queryParams = new URLSearchParams(location.search);
  return {
    client_id: queryParams.get(OAuth2QueryParameters.CLIENT_ID) ?? "",
    response_type: queryParams.get(OAuth2QueryParameters.RESPONSE_TYPE) ?? "",
    redirect_uri: queryParams.get(OAuth2QueryParameters.REDIRECT_URI) ?? "",
    scope: queryParams.get(OAuth2QueryParameters.SCOPE) ?? "",
  };
}

async function StartAuthenticationRequestResponse(oauth2Data: OAuth2Type): Promise<SuccessTokenStepResponse> {
  try {
    return await StartAuthenticationRequest(oauth2Data);
  } catch (e) {
    return JSON.parse(JSON.stringify(e));
  }
}

export function ExtractParameter(parameter: string): string {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  return queryParams.get(parameter) || "";
}