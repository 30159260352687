import React, {
  ReactNode,
  createContext,
  useContext,
  useMemo,
  useState,
} from "react";

export interface DateType {
  day: string;
  month: string;
  year: string;
  value: string;
}

export interface AccountType {
  step: number;
  name: string;
  surname: string;
  birthday: DateType;
  genderOption: string;
  gender: string;
  pronoun: string;
  username: string;
  nationality: string;
  acceptTerms: string;
}

export interface AccountContextType {
  accountData: AccountType;
  setAccountData: React.Dispatch<React.SetStateAction<AccountType>>;
}

const AccountContext = createContext<AccountContextType | undefined>(undefined);

interface AccountProviderProps {
  children: ReactNode;
}
export const AccountProvider = ({ children }: AccountProviderProps) => {
  const datas = {
    step: 1,
    name: "",
    surname: "",
    birthday: {
      day: "",
      month: "",
      year: "",
      value: "",
    },
    genderOption: "",
    gender: "",
    pronoun: "",
    username: "",
    nationality: "",
    acceptTerms: "",
    password: "",
    confirmPassword: "",
  };

  const [accountData, setAccountData] = useState<AccountType>({ ...datas });

  const contextValues = { accountData, setAccountData };
  const initialValues = useMemo(
    () => ({ ...contextValues }),
    [...Object.values(contextValues)]
  );

  return (
    <AccountContext.Provider value={initialValues}>
      {children}
    </AccountContext.Provider>
  );
};

export const useAccount = () => {
  const context = useContext(AccountContext);

  if (!context) {
    throw new Error("useAccount must be used within an AccountProvider");
  }

  return context;
};

