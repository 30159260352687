import React, { useState, ChangeEvent } from "react";

import {
  AccountType,
  DateType,
  useAccount,
} from "@contexts/AccountContext";

import { useNavigate } from "react-router-dom";

import AuthenticationForm from "@components/AuthenticationForm";
import FormField from "@components/Field";

import AccountService from "../service/AccountService";

import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import { ComposeURLWithGetQueryArguments } from "@helpers/get";
import PagePaths from "@authien/helpers/constants/PagePaths";
import FormSelect from "@components/Select";
import {
  cleanFormDataErrors,
  set_localStorage,
} from "@helpers/FormDataServices";
import {
  RegisterPersonalInformationRequest,
  StartTransactionRequest,
} from "../service/AccountRequest";
import { useOAuth2 } from "@contexts/OAuth2Context";
import { useTranslation } from "react-i18next";
import { t_birthdayGender } from "@i18n/usualTexts/t_birthdayGender";
import { delay, intervalMiliseconds } from "@helpers/delay";
import { showAuthError } from "../service/RegisterShowError";
import {
  CacheService,
  PERSONAL_INFO_POST_RESPONSE,
} from "@services/cache/cacheService";
import { dateFormat } from "../service/DateService";

export interface FormData {
  birthday: {
    value: DateType;
    errors: Set<string>;
  };
  genderOption: {
    value: string;
    errors: Set<string>;
  };
  gender: {
    value: string;
    errors: Set<string>;
  };
  pronoun: {
    value: string;
    errors: Set<string>;
  };
}

const BirthdayGenderPage: React.FC = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [submitted, setSubmitted] = useState(false);
  const [serverErrorPopUp, setServerErrorPopUp] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const { oauth2Data } = useOAuth2();
  const { accountData, setAccountData } = useAccount();
  const [formData, setFormData] = useState<FormData>({
    birthday: {
      value: {
        day: accountData.birthday?.day ?? localStorage.getItem("day") ?? "",
        month:
          accountData.birthday?.month ?? localStorage.getItem("month") ?? "",
        year: accountData.birthday?.year ?? localStorage.getItem("year") ?? "",
        value:
          accountData.birthday?.value ??
          localStorage.getItem("birthdate") ??
          "",
      },
      errors: new Set<string>(),
    },
    genderOption: {
      value:
        accountData.genderOption ?? localStorage.getItem("genderOption") ?? "",
      errors: new Set<string>(),
    },
    gender: {
      value: accountData.gender ?? localStorage.getItem("gender") ?? "",
      errors: new Set<string>(),
    },
    pronoun: {
      value: accountData.pronoun ?? localStorage.getItem("pronoun") ?? "",
      errors: new Set<string>(),
    },
  });

  const monthSelectOptions = t(
    t_birthdayGender.monthPlaceholderSelectOptions
  ).split(",");
  const genderOptions = t(
    t_birthdayGender.genderPlaceholderSelectOptions
  ).split(",");
  const pronounOptions = t(
    t_birthdayGender.pronounPlaceholderSelectOptions
  ).split(",");

  return (
    <AuthenticationForm
      title={t(t_birthdayGender.title)}
      subtitle={t(t_birthdayGender.subtitle)}
      back={back}
      onSubmit={() => handleSubmit()}
      isLoading={isLoading}
      serverErrorPopUp={serverErrorPopUp}
      setServerErrorPopUp={setServerErrorPopUp}
    >
      <Row>
        <Col>
          <FormField
            autofocus={true}
            name="day"
            type="text"
            required={true}
            onChange={handleFormData}
            value={formData.birthday.value.day}
            submitted={submitted}
            errors={formData.birthday.errors}
            toPresentErrors={false}
          >
            {t(t_birthdayGender.dayPlaceholder)}
          </FormField>
        </Col>
        <Col>
          <FormSelect
            name="month"
            required={true}
            onChange={handleFormSelect}
            value={formData.birthday.value.month}
            submitted={submitted}
            options={monthSelectOptions}
            errors={formData.birthday.errors}
            toPresentErrors={false}
          >
            {t(t_birthdayGender.monthPlaceholderSelect)}
          </FormSelect>
        </Col>
        <Col>
          <FormField
            name="year"
            type="text"
            required={true}
            onChange={handleFormData}
            value={formData.birthday.value.year}
            submitted={submitted}
            errors={formData.birthday.errors}
            toPresentErrors={false}
          >
            {t(t_birthdayGender.yearPlaceholder)}
          </FormField>
        </Col>
      </Row>

      <Row>
        <Col>
          {formData.birthday.errors.size > 0 &&
            [...formData.birthday.errors].map((error) => (
              <div
                key={error}
                data-testid="spanElement"
                className={"invalid-feedback d-block" + (submitted ? " shake-animation" : "")}
              >
                {error}
              </div>
            ))}
        </Col>
      </Row>

      <Row className="mt-4 px-0 m-0">
        <Col className="p-0 m-0">
          <FormSelect
            name="genderOption"
            required={true}
            onChange={handleFormSelect}
            value={formData.genderOption.value}
            errors={formData.genderOption.errors}
            submitted={submitted}
            options={genderOptions}
          >
            {t(t_birthdayGender.genderPlaceholderSelect)}
          </FormSelect>
        </Col>
      </Row>

      <Row>
        <Col>
          {formData.genderOption.value === genderOptions[2] && (
            <>
              <Row className="mt-4 px-0 m-0">
                <Col className="p-0 m-0">
                  <FormField
                    name="gender"
                    type="text"
                    required={true}
                    onChange={handleFormData}
                    value={formData.gender.value}
                    errors={formData.gender.errors}
                    submitted={submitted}
                  >
                    {t(t_birthdayGender.genderPlaceholder)}
                  </FormField>
                </Col>
              </Row>

              <Row className="mt-4 px-0 m-0">
                <Col className="p-0 m-0">
                  <FormSelect
                    name="pronoun"
                    required={true}
                    onChange={handleFormSelect}
                    value={formData.pronoun.value}
                    errors={formData.pronoun.errors}
                    submitted={submitted}
                    options={pronounOptions}
                  >
                    {t(t_birthdayGender.pronounPlaceholderSelect)}
                  </FormSelect>
                </Col>
              </Row>
            </>
          )}
        </Col>
      </Row>
    </AuthenticationForm>
  );

  function handleFormData(e: ChangeEvent<HTMLInputElement>) {
    const { value, name } = e.target;

    if (!(name === "gender"))
      formData.birthday.value[name as keyof DateType] = value;
    else {
      formData[name as keyof FormData].value = value;
    }

    formData.birthday.value.value = dateFormat(formData.birthday.value, monthSelectOptions);

    setFormData({
      ...formData,
    });
    cleanFormDataErrors(formData);
  }

  function handleFormSelect(selectValue: string, selectName: string) {

    if (!(selectName.includes("gender") || selectName === "pronoun"))
      formData.birthday.value[selectName as keyof DateType] = selectValue;
    else {
      formData[selectName as keyof FormData].value = selectValue;
      if (selectValue === genderOptions[2]) {
        formData.gender.value = "";
        formData.pronoun.value = "";
      }
    }

    formData.birthday.value.value = dateFormat(formData.birthday.value, monthSelectOptions);

    setFormData({
      ...formData,
    });
    cleanFormDataErrors(formData);
  }

  function back() {
    setAccountData({
      ...accountData,
      step: 1,
    });

    navigate(ComposeURLWithGetQueryArguments(PagePaths.SIGNUP.CREATEACCOUNT));
  }

  async function handleSubmit() {
    setSubmitted(true);
    setIsLoading(true);

    const step = 3;
    const newAccountData: AccountType = {
      ...accountData,
      birthday: formData.birthday.value,
      genderOption: formData.genderOption.value,
      gender: formData.gender.value,
      pronoun: formData.pronoun.value,
      step: step,
    };

    const cache = new CacheService(newAccountData, PERSONAL_INFO_POST_RESPONSE, accountData);
    try {
      checkThisFormDatas();

      await postFormDatas_and_navigate(cache, newAccountData);
    } catch (e) {
      const error = JSON.parse(JSON.stringify(e));
      showAuthError(error, setServerErrorPopUp, frontExceptionFunction, t);
      
      cache.postToCache(error);
    } finally {
      setIsLoading(false);

      set_localStorage(formData);
      localStorage.setItem("day", formData.birthday.value.day);
      localStorage.setItem("month", formData.birthday.value.month);
      localStorage.setItem("year", formData.birthday.value.year);
      localStorage.setItem("birthdate", formData.birthday.value.value);

      setFormData({
        ...formData,
      });

      await delay(1000);
      setSubmitted(false);
    }
  }

  async function postFormDatas_and_navigate(
    cache: CacheService,
    newAccountData: AccountType
  ) {
    if (!cache.getToCache()) {
      const startTransactionPostResponse = await StartTransactionRequest(
        oauth2Data
      );
      console.log(startTransactionPostResponse);

      const intervalMilisecondsValue = intervalMiliseconds(
        startTransactionPostResponse
      );
      await delay(intervalMilisecondsValue);

      const personalInfoPostResponse = await RegisterPersonalInformationRequest(
        accountData,
        formData.gender.value,
        formData.pronoun.value,
        formData.birthday.value.value,
        startTransactionPostResponse?.access_token
      );
      console.log(personalInfoPostResponse);

      cache.postToCache(personalInfoPostResponse);
    }

    setAccountData(newAccountData);

    navigate(ComposeURLWithGetQueryArguments(PagePaths.SIGNUP.INSERTUSERNAME));
  }

  function checkThisFormDatas() {
    const accountService: AccountService = new AccountService(t);

    accountService.checkDate(
      formData.birthday.value,
      "birthday",
      monthSelectOptions
    );
    accountService.checkSelect(
      formData.genderOption.value,
      "genderOption",
      t(t_birthdayGender.genderPlaceholderSelect),
      genderOptions
    );

    if (formData.genderOption.value !== genderOptions[2]) {
      formData.gender.value = formData.genderOption.value;
      formData.pronoun.value = formData.genderOption.value;
    } else {
      accountService.checkName(
        formData.gender.value,
        "gender",
        t(t_birthdayGender.genderPlaceholderSelect)
      );
      accountService.checkSelect(
        formData.pronoun.value,
        "pronoun",
        t(t_birthdayGender.pronounPlaceholderSelect),
        pronounOptions
      );
    }
  }

  function frontExceptionFunction(errorDescription: Array<string>) {
    formData[errorDescription[0] as keyof FormData].errors.add(
      errorDescription[1]
    );
  }
};

export default BirthdayGenderPage;
