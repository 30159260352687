/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { JWKS } from '../models/JWKS';
import type { OAuth2ServerMetadata } from '../models/OAuth2ServerMetadata';
import type { ProblemResponse } from '../models/ProblemResponse';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class OpenIdService {

    /**
     * OpenID Configuration
     * @returns OAuth2ServerMetadata Server metadata
     * @throws ApiError
     */
    public static getOpenIdConfiguration(): CancelablePromise<OAuth2ServerMetadata> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/oauth2/oidc/.well-known/openid-configuration',
        });
    }

    /**
     * Retrieve JSON Web Key Set (JWKS)
     * Retrieve JWKS containing public keys used for JWT verification. Clients must include their `client_id` and `client_secret` in the Authorization header using HTTP Basic Authentication to access this endpoint.
 * 
     * @returns JWKS Successful response containing the JWKS.
     * @returns ProblemResponse Unexpected response.
     * @throws ApiError
     */
    public static getOpenIdjwks(): CancelablePromise<JWKS | ProblemResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/oauth2/oidc/.well-known/jwks.json',
        });
    }

    /**
     * Get user information
     * Returns claims about the authenticated user.
     * @returns any User information response
     * @throws ApiError
     */
    public static getUserInfo(): CancelablePromise<{
/**
 * Subject - Identifier for the End-User at the Issuer.
 */
sub?: string;
/**
 * End-User's full name in displayable form including all name parts, possibly including titles and suffixes, ordered according to the End-User's locale and preferences.
 */
name?: string;
/**
 * Given name(s) or first name(s) of the End-User.
 */
given_name?: string;
/**
 * Surname(s) or last name(s) of the End-User.
 */
family_name?: string;
/**
 * Middle name(s) of the End-User.
 */
middle_name?: string;
/**
 * Casual name of the End-User that may or may not be the same as the given_name.
 */
nickname?: string;
/**
 * Shorthand name by which the End-User wishes to be referred to.
 */
preferred_username?: string;
/**
 * URL of the End-User's profile page.
 */
profile?: string;
/**
 * URL of the End-User's profile picture.
 */
picture?: string;
/**
 * URL of the End-User's Web page or blog.
 */
website?: string;
/**
 * End-User's preferred e-mail address.
 */
email?: string;
/**
 * True if the End-User's e-mail address has been verified; otherwise false.
 */
email_verified?: boolean;
/**
 * End-User's gender.
 */
gender?: string;
/**
 * End-User's birthday, represented as an ISO 8601:2004 [ISO8601‑2004] YYYY-MM-DD format. The year may be 0000, indicating that it is omitted. To represent only the year, YYYY format is allowed.
 */
birthdate?: string;
/**
 * String from zoneinfo [zoneinfo] time zone database representing the End-User's time zone.
 */
zoneinfo?: string;
/**
 * End-User's locale, represented as a BCP47 [RFC5646] language tag.
 */
locale?: string;
/**
 * End-User's preferred telephone number.
 */
phone_number?: string;
/**
 * True if the End-User's phone number has been verified; otherwise false.
 */
phone_number_verified?: boolean;
/**
 * End-User's preferred postal address.
 */
address?: {
/**
 * Full mailing address, formatted for display or use on a mailing label.
 */
formatted?: string;
/**
 * Full street address component, which may include house number, street name, PO BOX, and multi-line extended street address information.
 */
street_address?: string;
/**
 * City or locality component.
 */
locality?: string;
/**
 * State, province, prefecture, or region component.
 */
region?: string;
/**
 * Zip code or postal code component.
 */
postal_code?: string;
/**
 * Country name component.
 */
country?: string;
};
/**
 * Time the End-User's information was last updated, represented in Unix time (integer number of seconds since the Unix epoch).
 */
updated_at?: number;
}> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/oauth2/oidc/userinfo',
        });
    }

    /**
     * Logout User
     * Logs out the current user by invalidating the session.
     * @param requestBody 
     * @returns any Successfully logged out
     * @throws ApiError
     */
    public static logoutUser(
requestBody: {
/**
 * The URI to redirect the user to after logout.
 */
post_logout_redirect_uri: string;
},
): CancelablePromise<{
message: string;
}> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/oauth2/oidc/logout',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

}
