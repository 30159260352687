import React, { useState, ChangeEvent } from "react";

import { useNavigate } from "react-router-dom";

import AuthenticationForm from "@components/AuthenticationForm";
import FormField from "@components/Field";

import AccountService from "../../createaccount/service/AccountService";

import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import { ComposeURLWithGetQueryArguments } from "@helpers/get";
import PagePaths from "@authien/helpers/constants/PagePaths";
import { throwIfNotExpectedException } from "@authien/helpers/exceptions/Handler";
import { InvalidValueException } from "@authien/helpers/exceptions/Exception";
import TimeForNewVerify from "@components/TimeForNewVerify";
import { useAuthentication } from "@contexts/AuthenticationContext";
import { useTranslation } from "react-i18next";

interface FormData {
  secondfactorcode: {
    value: string;
    errors: Set<string>;
  };
}

const SecondFactorPage: React.FC = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [submitted, setSubmitted] = useState(false);

  const { authData, setAuthData } = useAuthentication();

  const [formData, setFormData] = useState<FormData>({
    secondfactorcode: {
      value: "",
      errors: new Set<string>(),
    },
  });

  return (
    <AuthenticationForm
      title="Verificação de Dois Fatores"
      subtitle="Digite o código de verificação enviado para o seu email"
      back={back}
      onSubmit={() => handleSubmit()}
    >
      <Row>
        <Col>
          <FormField
            autofocus={true}
            name="secondfactorcode"
            type="text"
            required={true}
            onChange={handleFormData}
            value={formData.secondfactorcode.value}
            errors={formData.secondfactorcode.errors}
            submitted={submitted}
          >
            Inserir código
          </FormField>
        </Col>
      </Row>

      <TimeForNewVerify sendCodeEmail={sendCodeEmail} />
    </AuthenticationForm>
  );

  function sendCodeEmail() {
    console.log("send code email");
    //TODO send code email
  }

  function handleFormData(e: ChangeEvent<HTMLInputElement>) {
    const { value, name } = e.target;

    formData[name as keyof FormData].value = value;
    setFormData({
      ...formData,
    });
  }

  function back() {
    setAuthData({
      ...authData,
      step: 2,
    });

    navigate(ComposeURLWithGetQueryArguments(PagePaths.LOGIN.PASSWORD));
  }

  function handleSubmit() {
    setSubmitted(true);

    try {
      const accountService: AccountService = new AccountService(t);

      // Clean Errors
      Object.keys(formData).forEach((data) =>
        formData[data as keyof FormData].errors.clear()
      );

      accountService.checkCode(formData.secondfactorcode.value, "secondfactorcode");

      const step = 6;

      setAuthData({
        ...authData,
        step: step,
      });

      localStorage.setItem("step", step.toString());

      navigate(ComposeURLWithGetQueryArguments(PagePaths.SIGNUP.INSERTPHONE));
    } catch (e) {
      throwIfNotExpectedException(e, InvalidValueException);

      const errorDescription = (e as InvalidValueException).description;

      formData[errorDescription[0] as keyof FormData].errors.add(
        errorDescription[1]
      );
    }

    setFormData({
      ...formData,
    });
  }
};

export default SecondFactorPage;
