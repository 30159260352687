import { OpenAPI } from "@services/auth";

export const ComposeURLWithGetQueryArguments = (url) => {
  const currentQueryString = window.location.search;
  return url + (!currentQueryString ? "" : currentQueryString);
};

export const ComposeURLWithResponseParameters = (redirectionResponse) => {

  // Base URL from the OpenAPI configuration
  const baseUrl = OpenAPI.BASE;

  // The specific endpoint/location from the redirection response
  const location = redirectionResponse.location;

  // Construct the full initial URL
  const fullUrl = new URL(location, baseUrl);

  // Get current URL parameters and merge with redirectionResponse parameters
  const searchParams = new URLSearchParams(window.location.search);

  // If redirectionResponse includes parameters, merge them:
  if (redirectionResponse.parameters) {
    Object.keys(redirectionResponse.parameters).forEach(key => {
      searchParams.set(key, redirectionResponse.parameters[key]);
    });
  }

  Object.keys(searchParams).forEach(key => {
    console.log(searchParams[key]);
  });

  // Set the search parameters to the URL
  fullUrl.search = searchParams.toString();

  // Return the complete URL string
  return fullUrl.toString();
};
