import { DateType } from "@contexts/AccountContext";
import { InvalidValueException } from "@authien/helpers/exceptions/Exception";

import { TFunction } from "i18next";
import { t_warningTexts } from "@i18n/usualTexts/t_warningTexts";

export function showAuthError(
  error: any,
  setServerErrorPopUp: React.Dispatch<React.SetStateAction<string | null>>,
  frontExceptionFunction: Function,
  t: TFunction<"translation", undefined>
) {
  console.log(error);

  if (error.description) {
    const errorDescription = (error as InvalidValueException).description;
    frontExceptionFunction(errorDescription);
  } else if (error.body) {
    if (error.body.title === "UsernameAlreadyExistsError")
    frontExceptionFunction(["username", t(t_warningTexts.signup.usernameAlreadyExistsError) ]);
    else if (error.body.title === "InvalidConfirmationCodeError")
    frontExceptionFunction(["usernamecode", t(t_warningTexts.signup.code) ]);
    else if (error.body.exception_uuid)
      setServerErrorPopUp(error.body.exception_uuid);
    else setServerErrorPopUp(error.body.title);
  } else if (error.message) setServerErrorPopUp(error.message);
}
