import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import PropTypes from "prop-types";
import { Col, Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { ThreeDots } from "react-loading-icons";
import { t_help } from "@i18n/usualTexts/t_help";

const NextButton = ({ isLoading }) => {
  const { t } = useTranslation();

  return (
    <Button
      variant="primary"
      type="submit"
      style={{ width: "85px", height: "38px" }}
    >
      {isLoading ? <ThreeDots width="30px" /> : t(t_help.nextButton)}
    </Button>
  );
};

export default NextButton;
