import React, { FunctionComponent } from "react";
import { ApiError } from "@services/auth";

export const ClientNotFound: FunctionComponent<{ client_id: string, error: ApiError}> = ({
  client_id, error
}) => (
  <>
    <h3>Client not found</h3>
    {error.message !== "Network Error"  ? <p>Client ID {client_id} is not registered.</p> : <p>Network error, check your connection.</p>}
  </>
);
