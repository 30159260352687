import React, { ReactNode } from "react";
import {
  ExtractParameter,
  OAuth2Type,
  useOAuth2,
} from "@contexts/OAuth2Context";
import { OAuth2QueryParameters } from "@features/authentication/constants/Oauth2";
import ErrorPage from "@pages/Error";
import { ClientNotFound } from "../ClientNotFound";
import { isSuccessTokenStepResponse } from "@helpers/isSuccessTokenStepResponse";
import LoadingPage from "@components/LoadingPage";
import { deleteState, verifyState } from "@authien/helpers/state_storage";

type RenderOAuth2Props = { children: ReactNode };
export const RenderOAuth2 = ({ children }: RenderOAuth2Props) => {
  const { oauth2Data, startAuthenticationRequestResponse, state } = useOAuth2();

  if (ExtractParameter("error")) deleteState(state);

  if (!verifyState(state)) window.location.href = oauth2Data.redirect_uri;
  else {
    if (!hasRequiredOAuth2Parameters(oauth2Data)) {
      const missingArguments = getMissingOauth2Arguments(oauth2Data);
      return <ErrorPage missingArguments={missingArguments} />;
    }

    console.log(startAuthenticationRequestResponse);

    if (!startAuthenticationRequestResponse) return <LoadingPage />;

    if (
      startAuthenticationRequestResponse &&
      !isSuccessTokenStepResponse(startAuthenticationRequestResponse)
    )
      return (
        <ClientNotFound
          client_id={oauth2Data.client_id}
          error={startAuthenticationRequestResponse}
        />
      );
  }
  return <>{children}</>;
};

function hasRequiredOAuth2Parameters(
  oauth2QueryParameters: OAuth2Type
): boolean {
  const { client_id, response_type, redirect_uri } = oauth2QueryParameters;
  return Boolean(client_id && response_type && redirect_uri);
}

function getMissingOauth2Arguments(
  oauth2QueryParameters: OAuth2Type
): string[] {
  const { client_id, redirect_uri, scope, response_type } =
    oauth2QueryParameters;
  const { CLIENT_ID, REDIRECT_URI, SCOPE, RESPONSE_TYPE } =
    OAuth2QueryParameters;

  const missingArguments: string[] = [];

  if (!client_id) missingArguments.push(CLIENT_ID);
  if (!redirect_uri) missingArguments.push(REDIRECT_URI);
  if (!scope) missingArguments.push(SCOPE);
  if (!response_type) missingArguments.push(RESPONSE_TYPE);

  return missingArguments;
}
