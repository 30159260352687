import React from "react";

import { useNavigate } from "react-router-dom";

import AuthenticationForm from "@components/AuthenticationForm";

import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import { ComposeURLWithGetQueryArguments } from "@helpers/get";
import PagePaths from "@authien/helpers/constants/PagePaths";
import Text from "@components/Text";
import TextButton from "@components/TextButton";
import { useTranslation } from "react-i18next";
import { t_congratulations } from "@i18n/usualTexts/t_congratulations";
import { Button } from "react-bootstrap";

const CongratulationsPage: React.FC = () => {
  const { t } = useTranslation();

  const toLoginPage = () => {
    window.location.href = ComposeURLWithGetQueryArguments(PagePaths.LOGIN.IDENTITY);
  };

  return (
    <AuthenticationForm
      title={t(t_congratulations.title)}
      subtitle={t(t_congratulations.subtitle)}
      onSubmit={() => { }}
    >

      <Row className="my-2 py-1 text-center">
        <Col>
          <Text>
          {t(t_congratulations.bodyText)}
          </Text>
        </Col>
      </Row>

      <Row className="flex-grow-1 text-lg-start mt-5">
        <Col className="text-center">
          <Button variant="primary" onClick={() => { toLoginPage() }}>
          {t(t_congratulations.loginLink)}
          </Button>
        </Col>
      </Row>

    </AuthenticationForm>
  );
};

export default CongratulationsPage;