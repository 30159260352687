import "bootstrap/dist/css/bootstrap.min.css"; // Import Bootstrap CSS
import Form from "react-bootstrap/Form";
import React, { ChangeEvent } from "react";

import "../assets/styles/animation.css";
import { useTranslation } from "react-i18next";
import { t_help } from "@i18n/usualTexts/t_help";

export interface FormCheckBoxProps {
  name: string;
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
  value: string;
  immediateValidation?: boolean;
  errors?: Set<string>;
  required?: boolean;
  submitted?: boolean;
  toPresentErrors?: boolean;
  children: React.ReactNode;
}

const FormCheckBox: React.FC<FormCheckBoxProps> = ({
  name,
  onChange,
  value,
  immediateValidation = true,
  errors = new Set(),
  required = false,
  submitted = false,
  toPresentErrors = true,
  children,
}) => {
  const { t } = useTranslation();
  const requiredErrorMessage = t(t_help.check);

  if (submitted && value !== "true" && required) {
    errors.clear();
    errors.add(requiredErrorMessage);
  } else if (value === "true") {
    errors.delete(requiredErrorMessage);
  }

  return (
    <div className={"form-floating " + errors.size ? "d-block" + (submitted ? " shake-animation" : "") : ""}>
      <Form.Check
        type="checkbox"
        data-testid={name}
        name={name}
        label={children}
        id={name}
        onChange={onChange}
        data-immediate-validation={immediateValidation}
        defaultChecked={Boolean(value)}
      />

      {errors.size > 0 &&
        toPresentErrors &&
        [...errors].map((error) => (
          <div key={error} data-testid="spanElement" className={"invalid-feedback d-block" + (submitted ? " shake-animation" : "")}>
            {error}
          </div>
        ))}
    </div>
  );
};

export default FormCheckBox;
