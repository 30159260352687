import "bootstrap/dist/css/bootstrap.min.css"; // Import Bootstrap CSS
import React, { useState, Fragment, ChangeEvent } from "react";

import "../assets/styles/animation.css";
import { useTranslation } from "react-i18next";
import { t_help } from "@i18n/usualTexts/t_help";

import { Combobox, Transition } from "@headlessui/react";
import { CheckIcon, ChevronUpDownIcon } from "@heroicons/react/20/solid";

export interface FormSelectProps {
  name: string;
  onChange: Function;
  value: string;
  immediateValidation?: boolean;
  errors?: Set<string>;
  required?: boolean;
  submitted?: boolean;
  options: Array<string>;
  toPresentErrors?: boolean;
  autofocus?: boolean;
  children: string;
}

interface PropsBox {
  value: string;
  selectedValue: string;
}

const FormSelect: React.FC<FormSelectProps> = ({
  name,
  onChange,
  value,
  immediateValidation = true,
  errors = new Set(),
  required = false,
  submitted = false,
  options,
  toPresentErrors = true,
  autofocus = false,
  children,
}) => {
  const { t } = useTranslation();
  const requiredErrorMessage = t(t_help.fieldOrSelect, {
    placeholder: children,
  });

  if (submitted && !value && required) {
    errors.clear();
    errors.add(requiredErrorMessage);
  } else if (value) {
    errors.delete(requiredErrorMessage);
  }

  const [selectedValue, setSelectedValue] = useState(value);
  const [query, setQuery] = useState(value);

  const filteredOptions =
    query === ""
      ? options
      : options.filter((option) => {
          return (
            option.toLowerCase().substring(0, query.length) ===
            query.toLowerCase()
          );
        });

  const onSelect = (value: string) => {
    setQuery(value);
    setSelectedValue(value);
    onChange(value, name);
  };

  const BoxListOption: React.FC<PropsBox> = ({ value, selectedValue }) => {
    return (
      <Combobox.Option value={value}>
        {({ active }) => (
          <div
            style={{
              marginRight: "8px",
              cursor: "pointer",
              padding: "8px",
              backgroundColor: active
                ? "lightblue"
                : value === selectedValue
                ? "lightgray"
                : "transparent",
            }}
          >
            {value}
          </div>
        )}
      </Combobox.Option>
    );
  };

  return (
    <div style={{ position: "relative" }}>
      <Combobox value={selectedValue} onChange={onSelect}>
        <Combobox.Button
          style={{
            border: "none",
            paddingInline: "0",
            width: "100%",
            backgroundColor: "white",
            outline: "0",
          }}
        >
          <div className="form-floating">
            <Combobox.Input
              className={`form-control ${errors.size ? "is-invalid" : ""}`}
              placeholder={children}
              autoFocus={autofocus}
              required={required}
              data-immediate-validation={immediateValidation}
              name={name}
              id={name}
              onChange={(event) => setQuery(event.target.value)}
              autoComplete="off"
              value={query}
            />
            <label className="form-label" htmlFor={name}>
              {children}
            </label>
          </div>
        </Combobox.Button>
        <Combobox.Options
          style={{
            position: "absolute",
            top: "100%",
            left: "0%",
            width: "100%",
            background: "white",
            border: "1px solid lightgray",
            borderRadius: "5px",
            listStyle: "none",
            maxHeight: "130px",
            overflowY: "auto",
            padding: "0",
            zIndex: "3",
            outline: "none",
          }}
        >
          {filteredOptions.length === 0 && query !== "" ? (
            <div
              style={{
                marginRight: "8px",
                padding: "8px",
              }}
            >
              Nothing found.
            </div>
          ) : (
            filteredOptions.map((value: string) => (
              <BoxListOption
                key={value}
                value={value}
                selectedValue={selectedValue}
              />
            ))
          )}
        </Combobox.Options>
      </Combobox>

      {errors.size > 0 &&
        toPresentErrors &&
        [...errors].map((error) => (
          <div
            key={error}
            data-testid="spanElement"
            className={
              "invalid-feedback d-block" + (submitted ? " shake-animation" : "")
            }
          >
            {error}
          </div>
        ))}
    </div>
  );

  function handleFormData(e: ChangeEvent<HTMLInputElement>) {
    const { value } = e.target;
    setSelectedValue(value);
  }
};

export default FormSelect;
