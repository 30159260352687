import axios from 'axios';

async function reCaptchaRequest(token: string, user_action: string | undefined) {
    const API_KEY = process.env.REACT_APP_CLOUD_API_KEY ?? ""

    const requestBody = JSON.stringify({
        "event": {
            "token": token,
            "expectedAction": user_action,
            "siteKey": "6LdHzJwpAAAAAKcTI99aSW2w4QqAv7Ix5dg7QDZb",
        }
    });

    const url = `https://recaptchaenterprise.googleapis.com/v1/projects/k4d-authentication/assessments?key=${API_KEY}`;

    try {
        const response = await axios.post(url, requestBody, {
            headers: {
                'Content-Type': 'application/json'
            }
        });

        console.log('Stats:', response.status);
        console.log('Response:', response.data);
    } catch (error) {
        console.error('Request Error:', error);
    }
}

export default reCaptchaRequest;