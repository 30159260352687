import React, { createContext, useContext, useMemo, useState } from "react";
import { SuccessTokenStepResponse } from "@services/auth";

export interface AuthType {
  step?: number;
  username?: string;
  identityRequestResponse?: SuccessTokenStepResponse;
}

export interface AuthenticationContextType {
  authData: AuthType;
  setAuthData: React.Dispatch<React.SetStateAction<AuthType>>;
}

const AuthenticationContext = createContext<
  AuthenticationContextType | undefined
>(undefined);

type AuthenticationProviderProps = { children: React.JSX.Element };
export const AuthenticationProvider = ({
  children,
}: AuthenticationProviderProps) => {
  const step = 1;
  const username = "";
  const requestResponse = undefined;

  const [authData, setAuthData] = useState<AuthType>({
    step,
    username,
    identityRequestResponse: requestResponse,
  });

  const contextValues = { authData, setAuthData };
  const initialValues = useMemo(
    () => ({ ...contextValues }),
    [...Object.values(contextValues)]
  );

  return (
    <AuthenticationContext.Provider value={initialValues}>
      {children}
    </AuthenticationContext.Provider>
  );
};

export const useAuthentication = () => {
  const context = useContext(AuthenticationContext);

  if (!context) {
    throw new Error(
      "useAuthentication must be used within an AuthenticationProvider"
    );
  }

  return context;
};
