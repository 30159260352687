import { OAuth2QueryParameters } from "@authien/features/authentication/constants/Oauth2";

export function setState(state: string): void {
  localStorage.setItem(
    OAuth2QueryParameters.STATE,
    localStorage.getItem(OAuth2QueryParameters.STATE) + state + ", "
  );
}

export function deleteState(state: string): void {
  const state_storage = localStorage.getItem(OAuth2QueryParameters.STATE);
  if (state_storage) {
    localStorage.setItem(
      OAuth2QueryParameters.STATE,
      state_storage.replace(state + ", ", "")
    );
  }
}

export function verifyState(state: string): boolean {
  return (
    !localStorage.getItem(OAuth2QueryParameters.STATE)?.includes(state) ?? true
  );
}
