import React from "react";

import IdentityPage from "@features/authentication/page/Identity";
import PasswordPage from "@features/authentication/page/Password";
import PagePaths from "@authien/helpers/constants/PagePaths";
import BirthdayGenderPage from "@features/createaccount/page/BirthdayGenderPage";
import CreateAccountPage from "@features/createaccount/page/CreateAccountPage";
import InsertUsernamePage from "@features/createaccount/page/InsertUsernamePage";
import CreatePasswordPage from "@features/createaccount/page/CreatePasswordPage";
import CongratulationsPage from "@features/createaccount/page/CongratulationsPage";
import InsertUsernameCodePage from "@features/createaccount/page/InsertUsernameCode";
import SecondFactorPage from "@features/authentication/page/SecondFactor";
import OnBoardingPage from "@authien/features/createaccount/page/OnBoarding";
import { element } from "prop-types";

export const stepsAuthentication = [
  {
    stepNumber: 1,
    page: PagePaths.LOGIN.IDENTITY,
    requiredOAuthParams: ["client_id", "redirect_uri", "response_type"],
    element: <IdentityPage />,
  },
  {
    stepNumber: 2,
    page: PagePaths.LOGIN.PASSWORD,
    requiredOAuthParams: ["client_id", "redirect_uri"],
    element: <PasswordPage />,
  },
  {
    stepNumber: 3,
    page: PagePaths.LOGIN.SECONDFACTOR,
    requiredOAuthParams: ["client_id", "redirect_uri"],
    element: <SecondFactorPage />,
  },
];

export const stepsCreateAccount = [
  {
    stepNumber: 1,
    page: PagePaths.SIGNUP.INSERTUSERNAME,
    element: <InsertUsernamePage />,
  },
  {
    stepNumber: 2,
    page: PagePaths.SIGNUP.CREATEACCOUNT,
    element: <CreateAccountPage />,
  },
  {
    stepNumber: 3,
    page: PagePaths.SIGNUP.BIRTHDAYGENDER,
    element: <BirthdayGenderPage />,
  },
  {
    stepNumber: 4,
    page: PagePaths.SIGNUP.CREATEPASSWORD,
    element: <CreatePasswordPage />,
  },
  {
    stepNumber: 5,
    page: PagePaths.SIGNUP.VERIFYEMAIL,
    element: <InsertUsernameCodePage />,
  },
  {
    stepNumber: 8,
    page: PagePaths.SIGNUP.CONGRATULATIONS,
    element: <CongratulationsPage />,
  },
];

export const stepsOnBoarding = [
  {
    page: PagePaths.ONBOARDING,
    element: <OnBoardingPage />,
  },
];
