import React from "react";

import Divider from "./Divider";
import GoogleButton from "@components/button/Google";

import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { useTranslation } from "react-i18next";
import { OpenAPI } from "@services/auth";
import { setState } from "@authien/helpers/state_storage";
import { useOAuth2 } from "@authien/contexts/OAuth2Context";

const ExternalAuthentication: React.FC = () => {
  const { t } = useTranslation();
  const { state } = useOAuth2();

  const handleGoogleButtonClick = () => {
    setState(state)
    window.location.href = `${OpenAPI.BASE}/oauth2/oidc/google/login${window.location.search}`;
  };

  return (
    <>
      <Row>
        <Col>
          <Divider>{t('login.identity.orConnectWithText')}</Divider>
        </Col>
      </Row>

      <Row>
        <Col className="d-flex flex-row align-items-center justify-content-center">
          <GoogleButton link="" onClick={handleGoogleButtonClick}/>
        </Col>
      </Row>
    </>
  );
};

export default ExternalAuthentication;
