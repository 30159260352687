const PagePaths = {
  ERROR: "/error",
  MOCK: {
    AUTHENTICATION: {
      SUCCESS: "/mock/authentication/success",
    },
  },
  LOGIN: {
    IDENTITY: "/login/identity",
    PASSWORD: "/login/password",
    SECONDFACTOR: "/login/secondfactor",
  },
  SIGNUP: {
    CREATEACCOUNT: "/signup/createaccount",
    BIRTHDAYGENDER: "/signup/birthdaygender",
    INSERTUSERNAME: "/signup/insertusername",
    CREATEPASSWORD: "/signup/createpassword",
    VERIFYEMAIL: "/signup/verifyemail",
    INSERTPHONE: "/signup/insertphone",
    VERIFYPHONE: "/signup/verifyphone",
    CONGRATULATIONS: "/signup/congratulations",
  },
  ONBOARDING: "/external/onboarding"
};

export default PagePaths;
