import { SuccessTokenStepResponse } from "@services/auth";

export const intervalMiliseconds = (
  SuccessTokenStepResponse: SuccessTokenStepResponse | undefined
): number => {
  return SuccessTokenStepResponse?.not_before
    ? (SuccessTokenStepResponse.not_before -
        SuccessTokenStepResponse.issued_at) *
        1000
    : 0;
};

export const delay = (miliseconds: number) => {
  return new Promise<void>((resolve) => {
    setTimeout(() => {
      resolve();
    }, miliseconds);
  });
};

export const currentTimestamp = (): number => {
  const now = new Date();
  const oneSecondBefore = new Date(now.getTime() - 1000);
  const timestamp = Math.floor(oneSecondBefore.getTime() / 1000);

  return timestamp;
};
