import { Client, User } from "@features/authentication/services/OAuth2Service";

export const clientMocks: Array<Client> = [
  {
    client_id: "ca9b8729af2d50bcb915ff8d09b252a6",
    clientSecret: "123",
    redirect_uris: ["https://127.0.0.1:5000/oauth2/authorized"],
    clientLogoURL: process.env.PUBLIC_URL + "/img/housien-logo.png",
    clientName: "Housien",
    response_types: ["code"],
    scopes: ["openid"],
  },
  {
    client_id: "f45c587dd76e51789e7a60053c9977ba",
    clientSecret: "123",
    redirect_uris: ["http://127.0.0.1:5000/mock/callback"],
    clientLogoURL: process.env.PUBLIC_URL + "/img/housien-logo.png",
    clientName: "Housien",
    response_types: ["code"],
    scopes: ["openid"],
  },
];

export const userMocks: Array<User> = [
  {
    username: "test@local.com",
    telephone: "12988762323",
    password: "12345678",
    name: "Lucas Venezian Povoa",
  },
];
